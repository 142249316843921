const appConfig = {
    apiPrefix: "/api",
    authenticatedClientEntryPath: "/home",
    authenticatedAdminEntryPath: "/admin-dashboard",
    unAuthenticatedEntryPath: "/sign-in",
    tourPath: "/",
    locale: "en",
    enableMock: false,
};

export default appConfig;
