import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {
    const roleMatched = useMemo(() => {
        const ret = authority.some((role) => userAuthority.includes(role));
        // console.log(ret);
        return ret;
    }, [authority, userAuthority]);

    if (
        isEmpty(authority) ||
        isEmpty(userAuthority) ||
        typeof authority === "undefined"
    ) {
        return !emptyCheck;
    }

    return roleMatched;
}

export default useAuthority;
