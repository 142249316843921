import { useEffect } from "react";
import { THEME_ENUM } from "constants/theme.constant";
//----------------------------------------------------------------------------------IMPORT END

function useDarkMode() {
  // get local storage mode data
  const mode = localStorage.getItem("Mode");
  //get modes in theme enums
  const { MODE_DARK, MODE_LIGHT } = THEME_ENUM;

  // check the mode
  const isEnabled = mode === MODE_DARK;

  // mode change method
  const onModeChange = (mode) => {
    // set mode to local storage
    localStorage.setItem("Mode", mode);
    // set hard refersh to page
    window.location.href = "";
  };

  // do the theme changes according to mode
  useEffect(() => {
    if (window === undefined) {
      return;
    }
    const root = window.document.documentElement;
    root.classList.remove(isEnabled ? MODE_LIGHT : MODE_DARK);
    root.classList.add(isEnabled ? MODE_DARK : MODE_LIGHT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);

  return [isEnabled, onModeChange];
}

// export component
export default useDarkMode;
