import React from "react";

//----------------------------------------------------------------------------------IMPORT END

const commonRoute = [
  // Splash Page Route
  {
    key: "common.splash",
    path: "/",
    component: React.lazy(() => import("views/common/Splash/Splash.view")),
    authority: [],
  },
  // Privacy Policy Route
  {
    key: "common.privacyPolicy",
    path: "/privacy-policy",
    component: React.lazy(() => import("views/common/CommonPrivacyPolicy/PrivacyPolicy.view")),
    authority: [],
  },
  // Access Denied Route
  {
    key: "common.accessDenied",
    path: "/access-denied",
    component: React.lazy(() => import("views/common/AccessDenied/AccessDenied.view")),
    authority: [],
  },
  // temp quick access for info routeros, need to be deleted
  {
    key: "common.accessDenied",
    path: "/id/0000",
    component: React.lazy(() => import("views/common/TempAccess/TempAccess.view")),
    authority: [],
  },
];

// export component
export default commonRoute;
