import React, { memo, useMemo, lazy, Suspense } from "react";
import { Loading } from "components/shared";
import { useSelector } from "react-redux";
import {
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_BLANK,
} from "constants/theme.constant";
import useAuth from "utils/hooks/useAuth";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";
import commonRoute from "configs/routes.config/commonRoute";

//----------------------------------------------------------------------------------IMPORT END

// Different layouts for dashboards
const layouts = {
    [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
    [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
};

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type);

    // check the authentication statues
    const { authenticated } = useAuth();

    useDirection();

    useLocale();

    // create array of auth routes paths 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const array = [];
    Object.keys(commonRoute).forEach(key => {
        array.push(commonRoute[key].path);
    });

    // get current location route path name
    const pathname = window.location.pathname;

    // function for set layout for pages based on authentication and route name
    const AppLayout = useMemo(() => {
        if (authenticated) {
            // set one dashboard layout for all authenticated pages
            return layouts[layoutType];
        } else if (array.includes(pathname) && pathname !== "/") {
            // set AuthLayout for all Authentication pages
            return lazy(() => import("./CommonLayout/CommonLayout"));
        } else {
            // set CommonLayout for othe shared pages
            return lazy(() => import("./AuthLayout"));
        }
    }, [layoutType, authenticated, pathname, array]);

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    );
};

// export component
export default memo(Layout);
