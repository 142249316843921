import { useDispatch } from "react-redux";
import { setUser } from "store/auth/userSlice";
import { onSignInSuccess } from "store/auth/sessionSlice";
import { useAuth0 } from "@auth0/auth0-react";
// ----------------------------------------------------------------------------------------imports end

function useAuth() {
  // auth0 SDK
  const { isAuthenticated, user, loginWithRedirect, logout, getIdTokenClaims } = useAuth0();

  // redux
  const dispatch = useDispatch();

  // check if user is authenticated using auth0 SDK methods, also populates redux slices auth.user and auth.session with access from auth0
  const checkAuth = async () => {
    try {
      // if already authenticated proceed to get access token
      console.log(1);
      if (isAuthenticated) {
        console.log(2);
        // get access token without prompts as string
        const act = await (await getIdTokenClaims()).__raw;
        // const { token } = resp.data;
        // store access token here
        await dispatch(onSignInSuccess(`${act}`));
        // get user details from auth0 and store in auth.user
        await dispatch(
          setUser({
            picture: user.picture,
            email: `${user.email}`,
            email_verified: `${user.email_verified}`,
            name: `${user.name}`,
            nickname: `${user.nickname}`,
            sub: `${user.sub}`,
            updated_at: `${user.updated_at}`,
            // authority: ["USER"],
          }),
        );
        return {
          status: "success",
          message: "",
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  // sign into the application
  const signIn = async () => {
    try {
      // if already authenticated then check for authentication
      if (isAuthenticated) {
        await checkAuth();
      } else {
        // else redirect user to auth0 universal login page
        await loginWithRedirect();
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  // sign user out
  const signOut = async () => {
    // reset auth.user and auth.session to initial states
    localStorage.clear();
    // use auth0 sdk to logout, but use custom redirect after logout
    await logout({
      returnTo: process.env.REACT_APP_URL_LOGOUT_REDIRECT
    });
  };

  return {
    // use authentication checker from auth0 SDK, true if ok, false if login failed/not logged in
    authenticated: isAuthenticated,
    signIn,
    signOut,
    checkAuth,
  };
}

// export function
export default useAuth;
