import React, { useEffect } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import "./locales";
import { useAuth0 } from "@auth0/auth0-react";

// default options for the apollo client
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};
// initalise apollo client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  defaultOptions,
});

function setFontSize() {
  const fs = localStorage.getItem("fontSize");
  // eslint-disable-next-line no-unused-expressions
  fs == null ? "" : (document.getElementsByTagName("body")[0].style.fontSize = `${fs}px`);
}
setFontSize();

function App() {
  const { logout, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    // Define an async function to fetch the access token
    const getAccessToken = async () => {
      try {
        // Call the getIdTokenClaims() method to fetch the ID token
        const token = await getIdTokenClaims();
        let nowTime = new Date();
        nowTime = nowTime.getTime()/1000;
        // if there grate than expiray time error signout the user
        if(token.exp < nowTime) {
          // reset auth.user and auth.session to initial states
          localStorage.clear();
          // use auth0 sdk to logout, but use custom redirect after logout
          await logout({
            returnTo: process.env.REACT_APP_URL_LOGOUT_REDIRECT
          });
        }
      } catch (e) {
        await logout({
            returnTo: process.env.REACT_APP_URL_LOGOUT_REDIRECT
        });
      }
    };
    // run the getAccessToken in every 5mins
    const interval = setInterval(() => {
      getAccessToken();
    }, 5*60*1000);
    // clear the interval
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <Theme>
              <Layout />
            </Theme>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
